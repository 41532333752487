import type { PropsWithChildren, ReactNode } from 'react';
import {
  type LDFlagSet,
  LDProvider,
  PolymorphicIntlContextProvider,
  PolymorphicRouterContextProvider,
} from '@qonto/react-migration-toolkit/react/providers';
import { useEmberIntl, useEmberRouter } from '@qonto/react-migration-toolkit/react/hooks/providers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();
interface CustomProvidersProps extends PropsWithChildren {
  ldFlags: LDFlagSet;
}

export function CustomProviders({ children, ldFlags }: CustomProvidersProps): ReactNode {
  const intl = useEmberIntl();
  const router = useEmberRouter();

  return (
    <LDProvider ldFlags={ldFlags}>
      <PolymorphicRouterContextProvider router={router}>
        <PolymorphicIntlContextProvider intl={intl}>
          <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools buttonPosition="bottom-left" />
          </QueryClientProvider>
        </PolymorphicIntlContextProvider>
      </PolymorphicRouterContextProvider>
    </LDProvider>
  );
}
